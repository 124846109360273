<template>
  <v-col class="content">
    <v-col cols="6" md="6" class="goBack">
      <v-btn plain @click="$router.push({name: 'Login'})">
        <v-icon> mdi-arrow-left</v-icon>
        {{ $t('sign_up.button.back') }}
      </v-btn>
    </v-col>
    <v-form ref="form" v-model="valid" v-on:submit.prevent="createAccount" lazy-validation>
      <div class="form">
        <v-overlay :value="loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-col class="formCol">
          <!--  Create account form      -->
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-card-title class="formTitle">{{ $t('sign_up.form.title') }}</v-card-title>
          </v-col>
          <v-col cols="12" md="6" class="formContent">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1 "
                dense
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-text-field v-model="formData.email" :label="this.$t('sign_up.form.data.email')"
                          :rules="[rules.required, rules.email]"
                          required></v-text-field>
          </v-col>
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-text-field v-model="formData.nickname" :label="this.$t('sign_up.form.data.nickname')"
                          :rules="[rules.required, rules.nickname_length]"></v-text-field>
          </v-col>
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-text-field v-model="formData.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          :rules="[rules.required, rules.password_length, rules.password_number, rules.password_letter]"
                          @click:append="showPassword = !showPassword"
                          :label="this.$t('sign_up.form.data.password')"
                          required></v-text-field>
          </v-col>
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-text-field v-model="formData.password_confirm" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          ref="password_confirm"
                          :rules="[rules.required, rules.match]" @click:append="showPassword = !showPassword"
                          :label="this.$t('sign_up.form.data.password_confirm')" required></v-text-field>

          </v-col>
          <v-col cols="12" md="9" lg="6" class="formContent">
            <v-btn class="connectBtn" @click="createAccount">Sign up</v-btn>
          </v-col>
        </v-col>
      </div>
    </v-form>
  </v-col>
</template>

<script>


import _ from "@/tools/lodash";

export default {
  name: "CreateAccount",
  data() {
    return {
      valid: true, // whether or not the form is valid
      loading: false,
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        }
      },
      formData: {
        email: '',
        nickname: '',
        password: '',
        password_confirm: '',
      },
      rules: {
        required: value => !!value || this.$t('sign_up.form.rules.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('sign_up.form.rules.invalid_email')
        },
        nickname_length: value => value.length >= 2 && value.length <= 16 || this.$t('sign_up.form.rules.nickname_length'),
        match: value => (!!value && value) === this.formData.password || this.$t('sign_up.form.rules.password_match'),
        password_length: value => value.length >= 8 || this.$t('sign_up.form.rules.password_length'),
        password_number: value => {
          const pattern = /\d/
          return pattern.test(value) || this.$t('sign_up.form.rules.password_number')
        },
        password_letter: value => {
          const pattern = /[a-zA-Z]/
          return pattern.test(value) || this.$t('sign_up.form.rules.password_letter')
        }
      },
      showPassword: false,

    }
  },
  watch: {
    "formData.password": function () {
      this.$refs.password_confirm.validate()
    }
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },

    /**
     * Create a new user account
     * if login doesn't exist already
     * @returns {Promise<void>}
     */
    async createAccount() {
      if (this.$refs.form.validate()) {
        this.loading = true

        try {
          await this.axios.post('/user/add', {
            login: this.formData.email,
            email: this.formData.email,
            user_name: this.formData.nickname,
            password: this.formData.password
          })

          // build user firstname and last name string
          const user_name = (!_.isEmpty(this.formData.nickname) ? (this.formData.nickname + ' ') : '')

          this.alert('success', 'mdi-check-circle-outline', this.$t('sign_up.form.success_message'), true)

          setTimeout(async () =>
              // redirect to Login once account was created
              // send event with user name and login
              await this.$router.push({name: 'Login'}).then(() => {
                this.$root.$emit('account-created', {
                  user_login: this.formData.email,
                  user_name_string: user_name
                })
              }), 1000);

        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.invalid_params'), true)
                break

                // Unauthorized
              case 401:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.unauthorized'), true)
                break

                // Email already exists
              case 403:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.email_exists'), true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.invalid_method'), true)
                break

                // Username taken
              case 406:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.username_exists'), true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('sign_up.form.error.unknown'), true)
                break
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    /**
     * Keyboard enter action
     */
    keyboard_enter_action: function (e) {
      if (e.keyCode === 13) {
        this.createAccount()
      }
    },
    /**
     * Listen keyboard enter request
     */
    listen_keyboard_enter_request: function () {
      document.addEventListener("keydown", this.keyboard_enter_action, false);
    },
    /**
     * Stop listen keyboard enter request
     */
    stop_listen_keyboard_enter_request: function () {
      document.removeEventListener("keydown", this.keyboard_enter_action, false);
    }
  },
  mounted() {
    // prefill user login from event sent by LoginForm
    this.$root.$on('user-login', (data) => {
      this.formData.email = data
    })

    this.listen_keyboard_enter_request();
  },
  beforeDestroy() {
    this.$root.$off('user-login')
    this.stop_listen_keyboard_enter_request();
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-overlay__scrim {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
  }
}

.content {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;

  .goBack {
    height: 10%;
    margin: 5% 0 0 0;
  }

  .form {
    width: 100%;
    height: 80%;

    .formContent {
      margin: 0 auto;

      .formTitle {
        padding: 0;
        color: dimgray;
        font-size: 2rem;
        font-weight: bold;
      }

      .forgotten {
        font-size: 80%;
        display: flex;
        justify-content: right;
        color: RGBA(121, 7, 72, 1);
      }

      .v-progress-circular {
        margin: 1rem;
        color: RGBA(121, 7, 72, 1);
      }

      .connectBtn {
        width: 100%;
        padding: 0;
        color: #ffffff;
        font-size: 0.7rem;
        background-color: RGBA(121, 7, 72, 1);
      }

      .newAccount {
        padding: 2% 0 0 0;

        .account {
          color: RGBA(121, 7, 72, 1);

        }
      }

      .account {
        color: RGBA(121, 7, 72, 1);

      }
    }
  }
}

</style>
